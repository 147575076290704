import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3002';

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    });

    const [token, setToken] = useState(() => localStorage.getItem('auth-token') || null);

    useEffect(() => {
        const storedToken = localStorage.getItem('auth-token');
        if (!storedToken) {
            logout(); // Logout se o token estiver ausente
        } else {
            setToken(storedToken);
            if (user?.email) {
                checkSubscription(user.email);
            }
        }
    }, []);

    useEffect(() => {
        if (user?.email) {
            checkSubscription(user.email);
        }
    }, [user?.email]);

    const checkSubscription = async (email) => {
        try {
            const response = await fetch(`${apiUrl}/ncm/validatePlan?email=${encodeURIComponent(email)}`);
            const result = await response.json();

            if (result.plan === 'Pro') {
                setUser((prevUser) => ({ ...prevUser, plan: 'Pro' }));
            } else {
                setUser((prevUser) => ({ ...prevUser, plan: 'free' }));
            }
        } catch (error) {
            console.error('Erro ao verificar assinatura:', error);
        }
    };

    const login = ({ user, token }) => {
        setUser(user);
        setToken(token);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('auth-token', token);
        if (user.email) {
            checkSubscription(user.email);
        }
    };

    const logout = () => {
        setUser(null);
        setToken(null);
        localStorage.removeItem('user');
        localStorage.removeItem('auth-token');
    };

    return (
        <AuthContext.Provider value={{ user, token, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;