import React, { useState } from 'react';
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { MONTHLY_PRICE, ANNUAL_DISCOUNT, getAnnualPrice } from '../constants/price';

const dialogStyles = {
  '& .MuiPaper-root': {
    backgroundColor: '#121212',
    color: '#f2f2f2',
    padding: '20px',
    borderRadius: '15px',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.5)', // Deeper shadow for a premium feel
    maxWidth: '600px',
    minWidth: '400px',
  },
};

const titleStyles = {
  fontWeight: 'bold',
  fontSize: '24px',
  background: 'linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
};

const iconTitleStyles = {
  fontWeight: 'bold',
  color: '#f2f2f2',
};

const upgradeButtonStyles = {
  fontWeight: 'bold',
  background: 'linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)',
  color: '#ffffff',
  textTransform: 'none',
  '&:hover': {
    background: 'linear-gradient(135deg, #000DFF 0%, #6B73FF 100%)',
  },
  boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)', // Subtle shadow for depth
  borderRadius: '25px',
};

const planButtonStyles = {
  backgroundColor: '#1a1a1a',
  color: '#ffffff',
  borderRadius: '15px',
  border: '1px solid #444',
  padding: '10px 20px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#2a2a2a',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 10px', // Add margin for spacing
  boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)', // Shadow for buttons
  transition: 'all 0.3s ease', // Smooth transitions for hover effects
  opacity: 0.8,
};

const saveBadgeStyles = {
  backgroundColor: '#ff8c00',
  color: 'black',
  padding: '5px 10px',
  borderRadius: '25px',
  fontSize: '14px',
  fontWeight: 'bold',
  marginTop: '8px',
};

const UpgradePopup = ({ open, onClose }) => {
  const [selectedPlan, setSelectedPlan] = useState('anual'); // Default to "anual"

  const monthlyPrice = parseFloat(process.env.REACT_APP_MONTHLY_PRICE || MONTHLY_PRICE).toFixed(2);
  const annualPrice = parseFloat(getAnnualPrice(monthlyPrice, ANNUAL_DISCOUNT)).toFixed(2);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  const handleUpgradeClick = () => {
    const stripeLinks = {
      mensal: process.env.REACT_APP_STRIPE_MONTHLY_URL,
      anual: process.env.REACT_APP_STRIPE_ANNUAL_URL,
    };

    window.location.href = stripeLinks[selectedPlan];
  };

  const features = [
    {
      icon: <QueryBuilderIcon sx={{ fontSize: 40, color: '#3f51b5' }} />,
      title: 'Consultas Ilimitadas',
      description: 'Resultado assertivo com assistência de IA treinada.',
    },
    {
      icon: <AutoAwesomeIcon sx={{ fontSize: 40, color: '#3f51b5' }} />,
      title: 'Assistência Eficiente',
      description: 'Garanta resultados confiáveis evitando falhas e multas.',
    },
    {
      icon: <AllInclusiveIcon sx={{ fontSize: 40, color: '#3f51b5' }} />,
      title: 'Personalização Aumentada',
      description: 'Respostas mais relevantes com IA personalizada.',
    },
  ];

  return (
    <Dialog open={open} onClose={onClose} sx={dialogStyles}>
      <DialogTitle>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h5" sx={titleStyles}>
            Upgrade para Pro
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" align="center" style={{ marginBottom: '15px', color: '#cccccc' }}>
            Torne-se mais produtivo com nossa IA Treinada para facilitar as pesquisas de NCM e evite multas na importação.
          </Typography>
        </DialogContentText>
        {features.map((feature, index) => (
          <Box display="flex" alignItems="center" mb={2} key={index}>
            {feature.icon}
            <Box ml={2}>
              <Typography variant="body1" sx={iconTitleStyles}>
                {feature.title}
              </Typography>
              <Typography variant="body2" style={{ color: '#cccccc' }}>
                {feature.description}
              </Typography>
            </Box>
          </Box>
        ))}
        <Box display="flex" justifyContent="center" mt={3}>
          {/* Center the buttons */}
          <div
            style={{
              ...planButtonStyles,
              border: selectedPlan === 'mensal' ? '2px solid #ff8c00' : '1px solid #3f51b5',
              backgroundColor: selectedPlan === 'mensal' ? '#2a2a2a' : '#1a1a1a',
              opacity: selectedPlan === 'mensal' ? '1' : '0.6', // Dim the unselected plan
            }}
            onClick={() => handlePlanSelect('mensal')}
          >
            <Typography variant="body2" style={{ color: '#cccccc' }}>
              Plano Mensal
            </Typography>
            <Typography variant="h6" style={{ color: '#ffffff', fontWeight: 'bold' }}>
              R$ {monthlyPrice}/mês
            </Typography>
          </div>
          <div
            style={{
              ...planButtonStyles,
              border: selectedPlan === 'anual' ? '2px solid #ff8c00' : '1px solid #3f51b5',
              backgroundColor: selectedPlan === 'anual' ? '#2a2a2a' : '#1a1a1a',
              opacity: selectedPlan === 'anual' ? '1' : '0.6', // Dim the unselected plan
            }}
            onClick={() => handlePlanSelect('anual')}
          >
            <Typography variant="body2" style={{ color: '#cccccc' }}>
              Plano Anual
            </Typography>
            <Typography variant="h6" style={{ color: '#ffffff', fontWeight: 'bold' }}>
              R$ {annualPrice}/ano
            </Typography>
            <Box sx={saveBadgeStyles} mt={1}>
              Economize 10%
            </Box>
          </div>
        </Box>
      </DialogContent>
      <DialogActions style={{ flexDirection: 'column', alignItems: 'center' }}>
        <Button
          onClick={handleUpgradeClick}
          sx={{ ...upgradeButtonStyles, width: '100%', fontSize: '18px', padding: '12px' }}
        >
          Contrate Agora!
        </Button>
        <Button onClick={onClose} sx={{ marginTop: '10px', color: '#aaaaaa', textTransform: 'none' }}>
          Não agora
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradePopup;