import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, CircularProgress, Box, Button } from '@mui/material';
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
        h4: {
            fontWeight: 700,
        },
        body1: {
            fontSize: '1.1rem',
        },
    },
});

const Success = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Redirecionar após 1 segundo
        const timer = setTimeout(() => {
            navigate('/consulta');
        }, 1000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
                <CheckCircleOutlineIcon style={{ fontSize: 80, color: 'green' }} />
                <Typography variant="h4" component="h1" gutterBottom>
                    Registro bem-sucedido!
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                    Você será redirecionado para a tela de consulta em breve...
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" my={4}>
                    <CircularProgress />
                </Box>
                <Button variant="contained" color="primary" onClick={() => navigate('/consulta')}>
                    Ir para consulta agora
                </Button>
            </Container>
        </ThemeProvider>
    );
};

export default Success;