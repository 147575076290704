// src/hooks/useBillingCycle.js
import { useState } from 'react';
import { getAnnualPrice, MONTHLY_PRICE, ANNUAL_DISCOUNT } from '../constants/price';

export const useBillingCycle = () => {
  const [billingCycle, setBillingCycle] = useState('monthly');
  const annualPrice = getAnnualPrice(MONTHLY_PRICE, ANNUAL_DISCOUNT);
  
  return {
    billingCycle,
    annualPrice,
    monthlyPrice: MONTHLY_PRICE,
    setBillingCycle,
  };
};