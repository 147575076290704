import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Typography, Container, Box, Paper, ToggleButtonGroup, ToggleButton, CssBaseline, Button } from '@mui/material';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import PricingCard from './PricingCard';
import { useBillingCycle } from '../hooks/useBillingCycle';
import logo from '../assets/IconAzul.png';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#ff9800',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    h4: {
      fontWeight: 700,
    },
    body1: {
      fontSize: '1.1rem',
    },
    button: {
      textTransform: 'none',
      fontSize: '1rem',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: 50,
  overflow: 'hidden',
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0),
    border: 0,
    '&:not(:first-of-type)': {
      borderRadius: '0 50px 50px 0',
      borderLeft: `1px solid ${theme.palette.primary.main}`,
    },
    '&:first-of-type': {
      borderRadius: '50px 0 0 50px',
    },
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    '&:not(.Mui-selected)': {
      backgroundColor: '#e0e0e0',
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  padding: theme.spacing(1, 4),
  transition: 'all 0.3s ease',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  '&:not(.Mui-selected)': {
    backgroundColor: '#e0e0e0',
  },
}));

const Home = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { billingCycle, annualPrice, monthlyPrice, setBillingCycle } = useBillingCycle();

  const handleLoginClick = () => navigate('/login');

  const handleSubscribe = () => {
    const url = billingCycle === 'monthly'
        ? process.env.REACT_APP_STRIPE_MONTHLY_URL
        : process.env.REACT_APP_STRIPE_ANNUAL_URL;
    window.location.href = url;
  };

  return (
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>Consulta de NCM - IA Treinada para Consultas Precisas</title>
          <meta name="description" content="Utilize nossa IA treinada para realizar consultas precisas de NCM." />
          <meta name="keywords" content="NCM, consulta NCM, IA, Inteligência Artificial, NCM Brasil, consulta precisa NCM" />
          <meta property="og:title" content="Consulta de NCM - IA Treinada para Consultas Precisas" />
          <meta property="og:description" content="Utilize nossa IA treinada para realizar consultas precisas de NCM." />
          <meta property="og:image" content={logo} />
        </Helmet>
        <CssBaseline />
        <AppHeader />
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: 'linear-gradient(to bottom, #ffffff, #f3f4f6)',
              color: 'black',
              padding: 4,
            }}
        >
          <Container
              maxWidth="md"
              style={{
                position: 'relative',
                zIndex: 10,
                textAlign: 'center',
              }}
          >
            <Paper
                elevation={6}
                style={{
                  borderRadius: 16,
                  padding: '2rem',
                  marginTop: theme.spacing(2),
                  backgroundColor: '#ffffff',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                }}
            >
              <Box textAlign="center">
                <Typography variant={isMobile ? "h5" : "h3"} component="h1" gutterBottom>
                  Consulte o NCM de forma precisa para suas importações ou exportações.
                </Typography>
                <br />
                <Typography variant={isMobile ? "h6" : "h4"} component="h2" gutterBottom>
                  Evite multas e problemas com a nossa solução!
                </Typography>

                <img
                    src={logo}
                    alt="Logotipo"
                    style={{ width: isMobile ? '50%' : '25%', marginBottom: theme.spacing(2) }}
                />
                <Typography variant={isMobile ? "body1" : "h6"} paragraph>
                  Utilize nossa IA treinada para realizar consultas precisas de NCM.
                </Typography>
                <Box display="flex" justifyContent="center" style={{ marginBottom: 16 }}>
                  <StyledToggleButtonGroup
                      value={billingCycle}
                      exclusive
                      onChange={(event, newBillingCycle) => setBillingCycle(newBillingCycle)}
                      aria-label="Ciclo de cobrança"
                  >
                    <StyledToggleButton value="monthly" aria-label="Mensal">
                      Mensal
                    </StyledToggleButton>
                    <StyledToggleButton value="annually" aria-label="Anual">
                      Anual
                    </StyledToggleButton>
                  </StyledToggleButtonGroup>
                </Box>
                <Box display="flex" justifyContent="space-around" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
                  <PricingCard
                      title="Free"
                      price="Experimente nosso serviço gratuitamente por 1 dia."
                      buttonLabel="Começar"
                      buttonColor="primary"
                      onClick={handleLoginClick}
                  />
                  <PricingCard
                      title="Pro"
                      price={`R$ ${billingCycle === 'monthly' ? monthlyPrice : annualPrice}/${billingCycle === 'monthly' ? 'mês' : 'ano'}`}
                      description="Consultas ilimitadas e suporte completo."
                      buttonLabel="Assinar"
                      buttonColor="secondary"
                      onClick={handleSubscribe}
                      style={{
                        backgroundColor: '#ff9800',
                        color: '#fff',
                        boxShadow: '0 4px 20px rgba(255,152,0,0.4)',
                        borderRadius: 20,
                      }}
                  />
                </Box>
                <Box mt={4}>
                  <Button variant="contained" color="primary" onClick={handleLoginClick} size="large">
                    Acessar
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Container>
        </Box>
        <AppFooter />
      </ThemeProvider>
  );
};

export default Home;
