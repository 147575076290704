// src/components/PricingCard.js
import React from 'react';
import { Paper, Typography, Box, Button } from '@mui/material';

const PricingCard = ({ title, price, description, buttonLabel, onClick }) => (
  <Paper elevation={3} style={{ padding: 16, margin: 16, flex: 1, borderTop: '5px solid', borderRadius: 10 }}>
    <Typography variant="h5" gutterBottom>
      {title}
    </Typography>
    <Typography variant="body1" paragraph>
      {price}
    </Typography>
    {description && 
      <Typography variant="body2" paragraph>
        {description}
      </Typography>
    }
    <Box mt={2}>
      <Button variant="contained" color="primary" onClick={onClick}>
        {buttonLabel}
      </Button>
    </Box>
  </Paper>
);

export default PricingCard;