import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const AppFooter = () => (
  <Box
    component="footer"
    display="flex"
    justifyContent="center"
    alignItems="center"
    padding={2}
    color="black"
  >
    <Typography variant="body2">
      Powered by <Link href="https://qwize.io" color="inherit" style={{ textDecoration: 'none', fontWeight: 'bold' }}>QWize</Link>
    </Typography>
  </Box>
);

export default AppFooter;