import React, { useContext, useState, useEffect } from 'react';
import { AppBar, Toolbar, Box, IconButton, Button, Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { AuthContext } from '../AuthContext';
import logo from '../assets/LogoBranco.png';
import UpgradePopup from './UpgradePopup';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate

const UpgradeButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: '#ff9800',
  color: '#ffffff',
  textTransform: 'none',
  boxShadow: '0 4px 10px 0 rgba(255,152,0,0.39)',
  borderRadius: 20,
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: '#e68900',
    transform: 'scale(1.05)',
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: '#ffffff',
  backgroundColor: '#f44336',
  '&:hover': {
    backgroundColor: '#d32f2f',
  },
}));

const AppHeader = () => {
  const { user, logout } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const navigate = useNavigate(); // Usar o hook useNavigate

  useEffect(() => {
    console.log('User state:', user);
  }, [user]);

  const handleUpgradeClick = () => {
    setOpen(true);
  };

  const handlePopupClose = () => {
    setOpen(false);
  };

  const handleCancelPro = () => {
    setIsRedirecting(true);
    window.location.href = 'https://billing.stripe.com/p/login/7sIbKP6wo5rMdPi7ss';
  };

  const handleLogoClick = () => {
    navigate('/'); // Redirecionar para a página home
  };

  const renderButton = () => {
    if (!user) return null;

    if (user.plan === 'free') {
      return (
        <UpgradeButton variant="contained" onClick={handleUpgradeClick}>
          Upgrade para Pro
        </UpgradeButton>
      );
    } else if (user.plan === 'Pro') {
      return (
        <CancelButton
          onClick={handleCancelPro}
          disabled={isRedirecting}
        >
          {isRedirecting ? <CircularProgress color="inherit" size={24} /> : 'Cancelar Pro'}
        </CancelButton>
      );
    }

    return null;
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Box display="flex" alignItems="center" flexGrow={1}>
            <img
              src={logo}
              alt="Logo"
              style={{ width: 160, marginRight: 16, cursor: 'pointer' }} // Adicionar cursor pointer
              onClick={handleLogoClick} // Adicionar evento de clique
            />
          </Box>
          <Box display="flex" alignItems="center">
            {user && (
              <>
                <Typography variant="h6" color="inherit" sx={{ marginRight: 2 }}>
                  {user.name}
                </Typography>
                {renderButton()}
                <IconButton color="inherit" onClick={logout} sx={{ marginLeft: 2 }}>
                  <ExitToAppIcon />
                </IconButton>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <UpgradePopup open={open} onClose={handlePopupClose} />
    </>
  );
};

export default AppHeader;
