import React, { useState, useContext, useEffect } from 'react';
import { Button, TextField, Typography, Container, CircularProgress, Box, Paper, CssBaseline, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Alert } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthContext } from '../AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import logo from '../assets/IconAzul.png';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3f51b5',
        },
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
        h4: {
            fontWeight: 700,
        },
        body1: {
            fontSize: '1.1rem',
        },
    },
});

function Subscription() {
    const { user, token } = useContext(AuthContext);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [result, setResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (user) {
            console.log('Dados do usuário:', user);
        }
    }, [user]);

    if (!user) {
        return <Navigate to="/login" />;
    }

    const currentTime = new Date();
    const userCreationTime = new Date(user.createdAt);
    const isUserNew = (currentTime - userCreationTime) < (24 * 60 * 60 * 1000); // Menos de 24 horas
    const isUserPro = user.plan === 'Pro';

    if (!isUserPro && !isUserNew) {
        alert("Seu Plano Expirou. Faça agora um Upgrade para PRO e continue utilizando nossos serviços!");
        return <Navigate to="/login" />;
    }

    const handleSearch = async () => {
        const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3002";

        if (!apiUrl) {
            console.error("A variável de ambiente REACT_APP_API_URL não está definida.");
            return;
        }

        if (!token) {
            console.error("Token de autenticação não disponível.");
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch(`${apiUrl}/ncm/search?query=${searchTerm}`, {
                method: 'GET',
                headers: {
                    'Accept': '*/*',
                    'Authorization': `Bearer ${token}`,  // Inclua o token no header Authorization
                },
            });

            if (!response.ok) {
                if (response.status === 401) {
                    alert("Sua sessão expirou. Por favor, faça login novamente.");
                    navigate('/login');
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setResult(data);
        } catch (error) {
            console.error("Failed to fetch NCM data:", error.message);
            setResult({ recommendation: '', principalNcm: null, ncmResults: [] });
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppHeader />
            <Container maxWidth="sm">
                <Box my={4}>
                    <Paper elevation={3}>
                        <Box p={4} textAlign="center">
                            <img src={logo} alt="Logo" width="150" />
                            <Typography variant="h4" component="h1" gutterBottom>
                                Consulta NCM
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                label="Digite o termo de busca"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyPress={handleKeyPress} // Adicionado manipulador de eventos onKeyPress
                                disabled={!isUserPro && !isUserNew} // Desativa durante o período de teste ou se o usuário não for Pro
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSearch}
                                disabled={isLoading}
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'Procurar'}
                            </Button>
                        </Box>
                        <Box p={4}>
                            {error && <Alert severity="error">{error}</Alert>}
                            <Typography variant="h6" component="h2">
                                Resultado
                            </Typography>
                            {isLoading ? (
                                <p>Carregando...</p>
                            ) : (
                                result && (
                                    <>
                                        <Typography variant="body1">{result.recommendation}</Typography>
                                        {result.principalNcm ? (
                                            <Box my={2}>
                                                <Typography variant="h6" color="primary"> {result.principalNcm} </Typography>
                                            </Box>
                                        ) : (
                                            <Typography variant="body1">Nenhum principal código encontrado</Typography>
                                        )}
                                        {result.ncmResults && result.ncmResults.length > 0 ? (
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell><Typography variant="body1" fontWeight="bold">Código</Typography></TableCell>
                                                            <TableCell><Typography variant="body1" fontWeight="bold">Descrição</Typography></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {result.ncmResults.map((item, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>{item.codigo}</TableCell>
                                                                <TableCell>{item.descricao}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            <Typography variant="body1">Nenhum outro resultado encontrado</Typography>
                                        )}
                                    </>
                                )
                            )}
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <AppFooter />
        </ThemeProvider>
    );
}

export default Subscription;
