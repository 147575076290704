import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthProvider from './AuthContext';
import Home from './components/Home';
import AuthForm from './components/AuthForm';
import Subscription from './components/Subscription';
import ProtectedRoute from './components/ProtectedRoute';
import Success from './components/Success';

const App = () => {
    return (
        <AuthProvider>
            <Helmet>
                {/* Google Tag Manager */}
                <script>
                    {`
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id=GTM-XXXXXXX';f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-XXXXXXX');
                    `}
                </script>
            </Helmet>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<AuthForm />} />
                    <Route path="/success" element={<Success />} />
                    <Route path="/consulta" element={
                        <ProtectedRoute>
                            <Subscription />
                        </ProtectedRoute>
                    } />
                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
