import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('auth-token');
    const isAuthenticated = !!token;
    const location = useLocation();

    console.log('Token verificado no ProtectedRoute:', token);
    console.log('Usuário autenticado:', isAuthenticated);
    console.log('Rota atual:', location.pathname);

    return isAuthenticated ? children : <Navigate to="/login" state={{ from: location }} />;
};

export default ProtectedRoute;